import React from 'react';
import { useLocation } from 'react-router-dom';

const EventRaces = (props) => {
  const search = useLocation().search;
  const year = new URLSearchParams(search).get('year') ?? '2020';

  if (props.races && props.races.length > 0) {
    return (
      <div className="details-links">
        <h3>Biegi w ramach tej samej imprezy (tego samego cyklu)</h3>
        <table className="details-table">
          <thead>
            <tr>
              <th>Nazwa</th>
              <th width="80px">Dystans</th>
              <th width="85px">Data</th>
            </tr>
          </thead>
          <tbody>
            {props.races.map((item) => {
              let date = new Date();
              date.setTime(item.Date);
              return (
                <tr key={item.Path}>
                  <td>
                    <a
                      href={
                        'https://www.tropemultra.pl/zawody-' +
                        year +
                        '/' +
                        item.Path
                      }
                      target="_parent"
                    >
                      {item.Name}
                    </a>
                  </td>
                  <td>{item.Distance} km</td>
                  <td>
                    <span>{date.toLocaleDateString()}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
};

export default EventRaces;

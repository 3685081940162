import React from 'react';
import Map from './Map';
import ElevationChart from './ElevationChart';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const MapDetails = (props) => {
  const [point, setPoint] = useState({});

  const search = useLocation().search;
  const raceId = new URLSearchParams(search).get('raceId');
  const year = new URLSearchParams(search).get('year') ?? '2020';

  const handlePositionChanged = (index) => {
    let newPoint = {
      lng: props.coordinates[index][0],
      lat: props.coordinates[index][1],
    };
    setPoint(newPoint);
  };

  if (props.coordinates) {
    return (
      <div className="details-map">
        <h3>Mapa i profil trasy biegu</h3>
        <Map coordinates={props.coordinates} point={point} />
        <ElevationChart
          data={props.elevationData}
          labels={props.labels}
          positionChanged={handlePositionChanged}
        />
        <div className="details-track">
          <a
            role="button"
            href={
              'https://d32drqhmigc832.cloudfront.net/tracks/' +
              year +
              '/' +
              raceId +
              '.gpx'
            }
          >
            Pobierz track GPX
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className="details-map">
      <h3>Mapa i profil trasy biegu</h3>
      <div>Niestety dla tych zawodów nie posiadamy tracka.</div>
    </div>
  );
};

export default MapDetails;

import React from 'react';
import calendar from './svg/calendar.svg';
import distance from './svg/distance.svg';
import elevation from './svg/elevation.svg';
import region from './svg/region.svg';
import size from './svg/size.svg';
import './RaceDetails.css';
import CategoryRaces from './CategoryRaces';
import EventRaces from './EventRaces';
import MapDetails from './MapDetails';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useEffect, useState } from 'react';

//import logo from './logo.svg';
//import './App.css';

const RaceDetails = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const search = useLocation().search;
  const raceId = new URLSearchParams(search).get('raceId');
  const year = new URLSearchParams(search).get('year') ?? '2020';

  useEffect(() => {
    const fetchData = async () => {
      let raceResponse = await fetch(
        'https://d32drqhmigc832.cloudfront.net/races/' +
          year +
          '/' +
          raceId +
          '.json'
      );
      if (raceResponse.ok) {
        let newData = {};
        newData.race = await raceResponse.json();

        let trackResponse = await fetch(
          'https://d32drqhmigc832.cloudfront.net/tracks/' +
            year +
            '/' +
            raceId +
            '.json'
        );

        if (trackResponse.ok) {
          let points = await trackResponse.json();

          newData.coordinates = points.map((point) => {
            return [point.lng, point.lat];
          });

          newData.elevationData = points.map((point) => [
            point.distance,
            point.elevation,
          ]);
          newData.labels = points.map((point) => point.distance.toString());
        }
        setData(newData);
      }
      setLoading(false);
    };
    fetchData();
  }, [raceId, year]);

  if (isLoading) {
    return <div>Ładowanie danych... Prosimy o chwilę cierpliwości.</div>;
  } else {
    if (!data.race) {
      return (
        <div>
          Bardzo przepraszamy, ale w tej chwili dane zawodów są niedostępne.
          Prosimy spróbować później.
        </div>
      );
    }
    let date = new Date();
    date.setTime(data.race.Date);
    let title = data.race.Name;
    let description = 'Informacje dotyczące biegu ' + data.race.Name;
    if (data.race.ShowEvent === 'true') {
      title += ' - ' + data.race.Event;
      description += ' rozgrywanego w ramach imprezy "' + data.race.Event + '"';
    }
    title += ' | tropem ULTRA';
    description +=
      ' - ' +
      data.race.Location +
      ' (' +
      data.race.MicroRegion +
      '). Mapa z trasą. Wykres przewyższeń oraz track GPX.';
    return (
      <div className="race-details-wrapper">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link
            rel="canonical"
            href={
              'https://www.tropemultra.pl/zawody-' + year + '/' + data.race.Path
            }
          />
        </Helmet>
        <div className="details-title">
          <h2>{data.race.Name}</h2>
          <p>{data.race.ShowEvent === 'true' ? data.race.Event : ''}</p>
        </div>
        <div className="race-details">
          <div className="details">
            <h3>Szczegóły zawodów</h3>
            <div>
              <img src={size} className="icon" alt="Kategoria biegu" />
              <span>Kategoria: </span>
              <span className="details-data">{data.race.Category}</span>
            </div>
            <div>
              <img src={distance} className="icon" alt="Dystans biegu" />
              <span>Dystans: </span>
              <span className="details-data">{data.race.Distance} km</span>
            </div>
            <div>
              <img
                src={elevation}
                className="icon"
                alt="Przewyższenia w górę"
              />
              <span>Przewyższenia: </span>
              <span className="details-data">
                +{data.race.Up} m / -{data.race.Down} m
              </span>
            </div>
            <div>
              <img
                src={region}
                className="icon"
                alt="Region, w którym odbywają się zawody"
              />
              <span>Lokalizacja: </span>
              <span className="details-data">{data.race.Location}</span>
            </div>
            <div>
              <div className="icon" />
              <span>Region: </span>
              <span className="details-data">{data.race.MicroRegion}</span>
            </div>
            <div>
              <img src={calendar} className="icon" alt="Data zawodów" />
              <span>Data: </span>
              <span className="details-data">
                {date.toLocaleString('pl-pl', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                })}
              </span>
            </div>
          </div>

          <MapDetails
            coordinates={data.coordinates}
            elevationData={data.elevationData}
            labels={data.labels}
          />

          <div className="details-links">
            <h3>Więcej informacji</h3>
            <a
              href={data.race.Link}
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Strona główna zawodów
            </a>
            {data.race.RegulationUrl && (
              <div>
                <a
                  href={data.race.RegulationUrl}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  Regulamin
                </a>
              </div>
            )}
            {data.race.RegistrationUrl && (
              <div>
                <a
                  href={data.race.RegistrationUrl}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  Zapisy
                </a>
              </div>
            )}
            {data.race.ResultsUrl && (
              <div>
                <a
                  href={data.race.ResultsUrl}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  Wyniki
                </a>
              </div>
            )}
            <br />
          </div>
        </div>

        <EventRaces races={data.race.eventRaces} />
        <CategoryRaces races={data.race.categoryRaces} />
        <div>
          <span>
            Powrót do{' '}
            <a
              href="https://www.tropemultra.pl/kalendarz-biegow-gorskich"
              target="_parent"
            >
              kalendarza z listą zawodów
            </a>
            .{'    '}
          </span>
          <span>
            Wykorzystane ikony pochodzą z{' '}
            <a
              href="http://www.onlinewebfonts.com"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              oNline Web Fonts
            </a>
            .
          </span>
        </div>
      </div>
    );
  }
};

export default RaceDetails;

import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import './Map.css';

mapboxgl.accessToken =
  'pk.eyJ1IjoicmFka29uIiwiYSI6ImNrOThocDE2cTAyNGQzbG15bTkxcHMyNTQifQ.UlxkbLzw24SCAgoT7Q1AYg';

const Map = (props) => {
  const mapContainerRef = useRef(null);

  const [zoom] = useState(13);
  const [map, setMap] = useState(null);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/radkon/cka5ejt3z0ae31iqr1k4udd9v',
      center: props.coordinates[0],
      zoom: zoom,
    });

    map.on('load', () => {
      map.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: props.coordinates,
          },
        },
      });

      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#ff686b',
          'line-width': 6,
          'line-opacity': 0.5,
        },
      });

      map.addSource('location', {
        type: 'geojson',
        data: {
          type: 'Point',
          coordinates: props.coordinates[0],
        },
      });

      map.addLayer({
        id: 'location',
        source: 'location',
        type: 'circle',
        paint: {
          'circle-radius': 5,
          'circle-color': '#007cbf',
          'circle-stroke-color': '#ffffff',
          'circle-stroke-width': 2,
          'circle-blur': 0.25,
        },
      });

      let minLng = props.coordinates[0][0];
      let maxLng = props.coordinates[0][0];
      let minLat = props.coordinates[0][1];
      let maxLat = props.coordinates[0][1];
      for (let i = 1; i < props.coordinates.length; i++) {
        if (props.coordinates[i][0] < minLng) minLng = props.coordinates[i][0];
        if (props.coordinates[i][0] > maxLng) maxLng = props.coordinates[i][0];
        if (props.coordinates[i][1] < minLat) minLat = props.coordinates[i][1];
        if (props.coordinates[i][1] > maxLat) maxLat = props.coordinates[i][1];
      }

      map.fitBounds([
        [minLng - 0.003, minLat - 0.003],
        [maxLng + 0.003, maxLat + 0.003],
      ]);

      setMap(map);
    });

    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map) {
      if (props.point) {
        map.getSource('location').setData({
          type: 'Point',
          coordinates: [props.point.lng, props.point.lat],
        });
        map.setLayoutProperty('location', 'visibility', 'visible');
      } else {
        map.setLayoutProperty('location', 'visibility', 'none');
      }
    }
  }, [map, props.point]);

  return (
    <div>
      <div className="map-container" ref={mapContainerRef} />
    </div>
  );
};

export default Map;

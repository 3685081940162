import React, { Component } from "react";
import Chart from "react-apexcharts";

class ElevationChart extends Component {
  constructor(props) {
    super(props);
    this.handleMouseMove = this.handleMouseMove.bind(this);

    this.state = {
        options: {            
            chart: {
              height: 200,
              width: 500,
              type: 'area',
              zoom: {
                  enabled: false
              },
              events: {
                mouseMove : this.handleMouseMove
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },            
            subtitle: {
              text: '',
              align: 'left'
            },
            fill: {
              type: ['gradient', 'solid'],
            },
            markers: {
              size: [0, 0]
            },
            labels: props.labels,
            xaxis: {
              type: 'numeric',
              tickAmount: 3
            },
            yaxis: {
              labels: {
                formatter(val) {
                    return val.toFixed(0);
                }
              }
            },
            tooltip: {              
              x: {
                formatter(val) {
                  return val.toFixed(2).toString() + ' km';
                }
              },
              y: {
                  formatter(val) {
                      return val.toFixed(0).toString() + ' m'
                  }
              }
            },
            legend: {
              show: false
            }
          },
        
     
          series: [
            {
              name: 'Wysokość',
              type: 'area',
              data: props.data
            },
            {
              name: 'Progress',
              type: 'scatter',
              data: [props.data[0]]
            }
          ]
    };
  }

  handleMouseMove = (event, chartContext, config) => {          
    if (config.dataPointIndex > -1) {
        this.props.positionChanged(config.dataPointIndex);
    }
  }; 

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="area"
              width="100%"
              height="150"
            />           
          </div>
        </div>
      </div>
    );
  }
}

export default ElevationChart;